import Default from 'gatsby-theme-carbon/src/templates/Default';
import {Entity, UserContext } from '@parallelpublicworks/entitysync';
import { Link, graphql } from "gatsby"
import elasticlunr from "elasticlunr"
import { Grid, Column, Row, Tile, Select, SelectItem, TextInput, NumberInput, Button, Pagination, ToastNotification, Search} from 'carbon-components-react';
import {getListings, mapGatsbyNode, getSalary, slugify} from "../util/";
import ListingSummary from "../components/listing-summary";
import React, {useContext, useState, useEffect} from 'react';
import { Close24 } from "@carbon/icons-react";
import oregonCitiesObj from "../../static/oregon-cities";
import { getDistance } from "../util/getDistance";
import { InlineNotification } from 'gatsby-theme-carbon';

function sortSearch(ids, userData){
  let items_array = []
  for (const id of ids) {
    let item = userData[id]
    items_array.push(item)
  }
  items_array = items_array.sort((a,b)=>b.attributes.timestamp - a.attributes.timestamp)
  let result = items_array.map(item=>{
    let timestamp = typeof item.attributes.field_publish_date == 'string' ? new Date(item.attributes.field_publish_date).getTime() : item.attributes.field_publish_date * 1000;
    let id = item.local_id ? item.local_id : item.id;
    return {id, timestamp}
  })
  result = result.sort((a,b)=>b.timestamp - a.timestamp)
  return result
}



const indexedAttributes = [
  'title',
  'field_company_name',
  'field_description',
  'field_required_qualifications',
  'field_preferred_qualifications',
  'field_benefits'
];

const indexedRelationships = [
  {field: 'field_category', term_query: 'allTaxonomyTermCategories'},
  {field: 'field_position', term_query: 'allTaxonomyTermPositionTypes'},
  {field: 'field_experience_level', term_query: 'allTaxonomyTermExperienceLevels'},
  {field: 'field_bilingual', term_query: 'allTaxonomyTermBilingual'},
]

export default function JobSearchPage({data, pageContext, location}){

  const [datePosted, setDatePosted] = useState(null);
  const [category, setCategory] = useState(null);
  const [positionType, setPositionType] = useState(null);
  const [wageType, setWageType] = useState(null);
  const [experienceLevel, setExperienceLevel] = useState(null);
  const [bilingual, setBilingual] = useState(null);
  const [distance, setDistance] = useState(null);
  const [locality, setLocality] = useState(null);
  const [minimumSalary, setMinimumSalary] = useState(null);
  const [search, setSearch] = useState(null);

  const [index, setIndex] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [url, setUrl] = useState(null);
  const [cityAutocompleteState, setCityAutocomplete] = useState([]);



  useEffect(() => {
    setIndex(elasticlunr((function(){
      for(let attr of indexedAttributes){
        this.addField(attr);
      }
      for(let rel of indexedRelationships){
        this.addField(rel.field);
      }
      this.setRef('id');
    })));
  }, []);

  useEffect(() => {
    setUrl(new URLSearchParams(location.search));
  }, [location.search])

  useEffect(() => {
    if(url){
      setDatePosted(getTermStateFromUrl('DatesPosted', 'date-posted'));
      setCategory(getTermStateFromUrl('Categories', 'category'));
      setPositionType(getTermStateFromUrl('PositionTypes', 'position-type'));
      setWageType(getTermStateFromUrl('SalaryTypes', 'wage-type'));
      setExperienceLevel(getTermStateFromUrl('ExperienceLevels', 'experience-level'));
      setBilingual(getTermStateFromUrl('Bilingual', 'bilingual'));
      setDistance(getTermStateFromUrl('Distances', 'distance', 'drupal_id', ''));
      setLocality(getAttrStateFromUrl('locality') ? getAttrStateFromUrl('locality') : null)
      setMinimumSalary(Number.parseFloat(getAttrStateFromUrl('salary-minimum')) ? Number.parseFloat(getAttrStateFromUrl('salary-minimum')) : null);
      setSearch(getAttrStateFromUrl('search'));
    }
  }, [url])

  function getTermNodes(termType){
    return data &&
      data[`allTaxonomyTerm${termType}`]  &&
      data[`allTaxonomyTerm${termType}`].nodes ?
      data[`allTaxonomyTerm${termType}`].nodes : [];
  }

  const salaryTypes = getTermNodes('SalaryTypes');
  const applicationMethods = getTermNodes('ApplicationMethods');

  function getTermStateFromUrl(termType, queryParam, retrieve_key = 'drupal_id', default_empty_val = null){
    if(url){
      const termNodes = getTermNodes(termType);
      const rawUrlVal = url.has(queryParam) ? url.get(queryParam).split(',') : [default_empty_val];
      let urlVal;
      if(rawUrlVal.length > 1){
        urlVal = rawUrlVal;
      }
      else{
        urlVal = rawUrlVal[0]
      }

      let state = default_empty_val;
      if(Array.isArray(urlVal)){
        state = [];
        for(let node of termNodes){
          if(node.name && node[retrieve_key] && urlVal.includes(slugify(node.name))){
            state.push(node[retrieve_key]);
          }
        }
      }
      else{
        for(let node of termNodes){
          if(urlVal && node.name  && node[retrieve_key] && slugify(node.name) === urlVal){
            state = node[retrieve_key];
          }
        }
      }
      return state;
    }
  }

  function getAttrStateFromUrl(queryParam){
    if(url){
      const rawUrlVal = url.has(queryParam) ? url.get(queryParam).split(',') : [null];
      let urlVal;
      if(rawUrlVal.length > 1){
        urlVal = rawUrlVal;
      }
      else{
        urlVal = rawUrlVal[0]
      }
      return urlVal;
    }
  }

  function getUrlFromTermState(termType, val){
    const termNodes = getTermNodes(termType);
    for(let node of termNodes){
      if(node.name && node.drupal_id && node.drupal_id === val){
        return slugify(node.name);
      }
    }
    return null;
  }

  function getTermTitleFromID(id, termName, ret = 'name') {
    if(id){
      const termNodes = getTermNodes(termName);
      for(let node of termNodes){
        if(node.drupal_id && node.drupal_id === id){
          return node[ret] ? node[ret] : node;
        }
      }
    }
    return null;
  }

  let newAdd = null;
  if(typeof window !== `undefined`){      
    let queryString = window.location.search;
    if(queryString.length){      
      let urlParams = new URLSearchParams(queryString);
      newAdd = urlParams.get('new');
    }
  }

  const userContext = useContext(UserContext);
  if(!location || ! Array.isArray(userContext)){
    return (
      <></>
    );
  }


  const cityAutocompleteIndex = elasticlunr(function(){
    this.addField('name');
    this.setRef('name');
  });

  for(let cityName of Object.keys(oregonCitiesObj)){
    cityAutocompleteIndex.addDoc({name: cityName})
  }


  const [{userData}] = userContext;

  function updateSearchState(setState, termType, urlParam, e){
    const val = e.target.value;
    let urlVal = termType ? getUrlFromTermState(termType, val) : val;
    if(termType === 'SalaryTypes' && val === 'na'){
      urlVal = 'na'
    }
    setState(val);
    const url = new URLSearchParams(window.location.search);
    if(urlVal){
      url.set(urlParam, urlVal);
    }
    else{
      url.delete(urlParam);
    }
    window.history.replaceState(null, "", `?${url.toString()}`);
  }

  function clearFilters(){
    setDatePosted(null);
    setCategory(null);
    setPositionType(null);
    setWageType(null);
    setExperienceLevel(null);
    setBilingual(null);
    setDistance(null);
    setMinimumSalary(null);
    setSearch(null);
    setLocality(null);
    window.history.replaceState(null, "", `?`);
  }

  function getDatePostedText(date_posted, defaultText = 'Last 24 hours') {
    let datePostedText = defaultText;
    if(date_posted){
      for(let node of data.allTaxonomyTermDatesPosted.nodes.map(mapGatsbyNode)){
        if(node.id === date_posted){
          datePostedText = node.attributes.name;
          break;
        }
      }
    }
    return datePostedText;
  }

  function getSearchSummary(){

    let dateText = getDatePostedText(datePosted, '')
    if(dateText.length) dateText = dateText.toLowerCase()
    let cat = getTermTitleFromID(category, 'Categories')
    let type = getTermTitleFromID(positionType, 'PositionTypes')
    if(type){
      type = type.toLowerCase()
    }else{
      type = ''
    }
    let salary_type = getTermTitleFromID(wageType, 'SalaryTypes')
    if(salary_type) salary_type = salary_type.toLowerCase()
    let salary_number = getSalary(minimumSalary, getTermTitleFromID(wageType, 'SalaryTypes', 'object'))
    let level = getTermTitleFromID(experienceLevel, 'ExperienceLevels')
    if(level) level = level.toLowerCase()
    let bilingual_title = getTermTitleFromID(bilingual, 'Bilingual')
    if(bilingual_title) bilingual_title = bilingual_title.toLowerCase()
    let dist = getTermTitleFromID(distance, 'Distances')

    let summary = cat ? `<span class="underline">${cat}</span>` : `All`;
    if(cat && type) summary += `, `;
    if(type) summary += ` <span class="underline">${type}</span>`
    summary += ' listings';
    if(search && search.length) summary += ` containing "<span class="underline">${search}</span>"`;
    if(dateText) summary += ` posted during the <span class="underline">${dateText}</span>`
    if(salary_type && salary_type.includes('no')){
      summary += ` with <span class="underline">no salary listed</span> `
    }else if(salary_type || salary_number){
      if(salary_type && salary_number) {
        summary += ` with a minimum wage of <span class="underline">${salary_number}</span>`
      }else if(salary_type){
        summary += ` with a <span class="underline">${salary_type}</span> wage type `
      }
    }
    if(level) summary += ` in <span class="underline">${level} level</span>`
    if(bilingual_title) summary += ` and <span class="underline">${bilingual_title}</span>`
    if(dist) summary += ` within <span class="underline">${dist}</span>`
    if(results.length) summary += ` - <span class="underline">${results.length}</span> found`;
    else summary += ` - nothing found`;
    return `${summary}`;
  }




    // match a given job listing with the current search
  function matchSearch(_userData, id){
    const x = _userData[id];
    const rels = x.relationships;
    function matchEqualsField([field_name, field_value]){
      field_name = `field_${field_name}`;
      if(field_value && (!rels || (rels && !rels[field_name]))) return false;
      // if we have included this field in our search and the search doesn't match this entity, dont include it in the result
      if(field_value &&
        !(rels[field_name] && rels[field_name].data &&
          (Array.isArray(rels[field_name].data) &&
          rels[field_name].data.map(r => r.id ? r.id : r.local_id ).includes(field_value)) ||
          (rels[field_name].data.id ? rels[field_name].data.id : rels[field_name].data.local_id) === field_value)){
        return false;
      }
      return true;
    }

    // these four are straight equality checks (includes for the categories array, === for everything else),
    // passing if the job listing has the specified category, position, wage type, and experience level
    const equalsFields = [['category', category], ['position', positionType], ['experience_level', experienceLevel], ['bilingual', bilingual]];
    for(let field of equalsFields){
      if(!matchEqualsField(field)){
        return false
      }
    }

    if(wageType && !matchEqualsField(['salary_type', wageType])){
      return false
    }

    // if a time range is specified, check to see if this job posting is recent enough
    if(datePosted){

      // loop through the dates posted taxonomies and extract the text corresponding to the specified time range
      // (which is a term id)
      let datePostedText = getDatePostedText(datePosted);
      let minPostedDate = new Date();

      switch(datePostedText){
        case 'Last 24 hours':
          minPostedDate.setDate(minPostedDate.getDate() - 1);
          break;
        case 'Last 3 days':
          minPostedDate.setDate(minPostedDate.getDate() - 3);
          break;
        case 'Last 7 days':
          minPostedDate.setDate(minPostedDate.getDate() - 7);
          break;
        case 'Last 14 days':
          minPostedDate.setDate(minPostedDate.getDate() - 14);
          break;
      }

      const publishDateVal = Number.isInteger(x.attributes.field_publish_date) ?
      x.attributes.field_publish_date * 1000 : x.attributes.field_publish_date;
      if(new Date(publishDateVal) < minPostedDate){
        return false;
      }
    }

    // if a salary number and wage type have been specified, check if the job posting pays enough
    // (since we already know the wage type matches from the earlier check)
    let salary_number_parse = x.attributes.field_salary_number ? Number.parseFloat(x.attributes.field_salary_number) : null;
    let salary_number_parse_max = x.attributes.field_salary_number_max ? Number.parseFloat(x.attributes.field_salary_number_max) : null;
    let salary_number_compare = salary_number_parse_max ? salary_number_parse_max : salary_number_parse
    if(minimumSalary && wageType && salary_number_compare &&  salary_number_compare < minimumSalary){
      return false;
    }
    if(distance){
      if(!locality || !oregonCitiesObj[locality] || !x.attributes.field_location || !x.attributes.field_location.lat){
        return false;
      }
      const distanceBetween = getDistance(oregonCitiesObj[locality].lat, oregonCitiesObj[locality].lng, x.attributes.field_location.lat, x.attributes.field_location.lng,)
      const maxMiles = Number.parseInt(getTermTitleFromID(distance, 'Distances').split()[0]);
      if(maxMiles && distanceBetween > maxMiles){
        return false;
      }
    }
    // we've passed all our checks! this job listing should be displayed
    return true;
  }

  // add nodes retrieved from gatsby to the ones in the userData
  let currentListings = getListings(userData, data, null, false, index, indexedAttributes, indexedRelationships, data).filter(({id}) => matchSearch(userData, id));

  currentListings = currentListings.sort((a,b)=>b.timestamp - a.timestamp)



  const results = search ? sortSearch(index.search(search).map(e => e.ref), userData).filter(({id}) => matchSearch(userData, id)) : currentListings;
  pageContext = {...pageContext, frontmatter: {title: "Job Search"}};
  const searchSummary = getSearchSummary();

  const hasFilters = window.location.search !== "";
  const paginationProps = {
    disabled:  false,
    page: 1,
    totalItems: results.length,
    pagesUnknown: false,
    pageInputDisabled: undefined,
    backwardText: 'Previous page',
    forwardText: 'Next page' ,
    pageSize:  10,
    pageSizes: [10, 20, 30, 40, 50],
    itemsPerPageText: 'Items per page:',
    onChange: (e) => {
      setPageSize(e.pageSize);
      setPage(e.page);
      if(typeof document !== 'undefined'){
        setTimeout(() => {          
            var container = document.getElementById("main-page-container");
            container.scrollIntoView({behavior: "smooth", block: "start"});
        }, 10);
      }
    },
  };


  const wageTypeObj = wageType ? getTermTitleFromID(wageType, 'SalaryTypes', 'object') : null;
  const salary_number_label = wageTypeObj && wageTypeObj.name.toLowerCase().includes('hour') ? 'Minimum hourly rate:' : 'Minimum salary:';
  const wageTypeNone = wageTypeObj && wageTypeObj.name.toLowerCase().includes('no')

  return (
    <Default pageContext={pageContext} location={location}>
      <Grid className="job-search-grid">
        <span id="main-page-container"></span>
        <div className="job-search-heading"> <h2 dangerouslySetInnerHTML={{__html: searchSummary}}></h2>{hasFilters && <Link to="/job-search?" onClick={clearFilters}>Clear filters <Close24 /></Link>}</div>
        <Row>
          <Column lg={3} className="job-search-facets-col">
           
            <Select className="job-search-facet-select" light hideLabel value={datePosted ? datePosted : ''}
                     id="filter-date-posted"
                    onChange={updateSearchState.bind(null, setDatePosted, 'DatesPosted', 'date-posted')} >
              <SelectItem text="All times" />
              {
                getTermNodes('DatesPosted').map((term) => {
                  return (
                    <SelectItem text={term.name} value={term.drupal_id} key={term.drupal_id} />
                  )
                })
              }
            </Select>
            <Select className="job-search-facet-select" light hideLabel value={category ? category : ''} id="filter-category"
                    onChange={updateSearchState.bind(null, setCategory, 'Categories', 'category')}>
              <SelectItem text="All Categories" />
              {
                getTermNodes('Categories').map((term) => {
                  return (
                    <SelectItem text={term.name} value={term.drupal_id}  key={term.drupal_id}  />
                  )
                })
              }
            </Select>
            <Select className="job-search-facet-select" light hideLabel value={positionType ? positionType : ''} id="filter-position-type"
              onChange={updateSearchState.bind(null, setPositionType, 'PositionTypes', 'position-type')}>
              <SelectItem text="All position types" />
              {
                getTermNodes('PositionTypes').map((term) => {
                  return (
                    <SelectItem text={term.name} value={term.drupal_id}  key={term.drupal_id} />
                  )
                })
              }
            </Select>
            <Select className="job-search-facet-select" light hideLabel value={wageType ? wageType : ''} id="filter-wage-type"
                    onChange={updateSearchState.bind(null, setWageType, 'SalaryTypes', 'wage-type')}>
              <SelectItem text="All wage types" />
              {
                getTermNodes('SalaryTypes').map((term) => {
                  return (
                    <SelectItem text={term.name} value={term.drupal_id}  key={term.drupal_id} />
                  )
                })
              }
            </Select>
            {wageType && !wageTypeNone && <NumberInput className="job-search-salary-min" onChange={updateSearchState.bind(null, setMinimumSalary, null, 'salary-minimum')} allowEmpty light label={salary_number_label} value={minimumSalary ? minimumSalary : ""} />}
            <Select className="job-search-facet-select" light hideLabel value={experienceLevel ? experienceLevel : ''} id="filter-experience-level"
                    onChange={updateSearchState.bind(null, setExperienceLevel, 'ExperienceLevels', 'experience-level')}>
              <SelectItem text="All experience levels" />
              {
                getTermNodes('ExperienceLevels').map((term) => {
                  return (
                    <SelectItem text={term.name} value={term.drupal_id}  key={term.drupal_id} />
                  )
                })
              }
            </Select>
            <Select className="job-search-facet-select" light hideLabel value={bilingual ? bilingual : ''} id="filter-bilingual"
                    onChange={updateSearchState.bind(null, setBilingual, 'Bilingual', 'bilingual')}>
              <SelectItem text="All bilingual options" />
              {
                getTermNodes('Bilingual').map((term) => {
                  return (
                    <SelectItem text={term.name} value={term.drupal_id}  key={term.drupal_id} />
                  )
                })
              }
            </Select>
            <Select  light labelText="Within" value={distance ? distance : ''} id="filter-distance"
                    onChange={updateSearchState.bind(null, setDistance, 'Distances', 'distance')}>
              <SelectItem text="All distances" />
              {
                getTermNodes('Distances').map((term) => {
                  return (
                    <SelectItem text={term.name} value={term.drupal_id}  key={term.drupal_id}  />
                  )
                })
              }
            </Select>
            <TextInput light labelText="Of" value={locality ? locality : ""} placeholder="City" id="filter-city" onChange={(e) => {
              updateSearchState(setLocality, null, 'locality', e);
              setCityAutocomplete(cityAutocompleteIndex.search(e.target.value, {expand: true}));
            }}/>
            {
              cityAutocompleteState.length ? (
                <div className="city-suggestions">
                  <ul>
                    {
                      cityAutocompleteState.slice(0,5).map((doc) => {
                        return (
                          <li className="city-suggestion" onClick={() => {
                            updateSearchState(setLocality, null, 'locality', {target: {value: doc.ref}});
                            setCityAutocomplete([]);
                          }}>
                            {doc.ref}
                          </li>
                        );
                      })
                    }
                  </ul>
                </div>
              ) : <></>
            }
            {hasFilters && <Button kind="secondary" onClick={clearFilters}> Clear filters <Close24 /></Button>}
          </Column>
          <Column lg={9} className="job-search-results-col">
          <InlineNotification className="inline-notification" kind="info">Job postings may provide links to the websites of ODDS-approved job providers. Please be aware that these websites are the websites of the providers and not the websites of ODDS. <Link to="/legal-disclaimers">Click here for more information</Link>.</InlineNotification>
            <Search 
              light
              size='xl' 
              labelText="Search jobs by keyword"
              aria-label="Search Jobs"
              id="search-jobs"
              placeHolderText="Search job title, keywords, or company..."
              onChange={updateSearchState.bind(null, setSearch, null, 'search')} 
              value={search ? search : ""}
            />
            <div className="results-scroll">
            {
              results.slice((page - 1) * pageSize, page * pageSize).map(({id, timestamp}) => {
                if(id && !isNaN(timestamp)){
                  const source = id.startsWith('local') ? { local_id: id } : { id: id };
                  return (
                    <div key={id}>
                      <Entity source={source} type="node--job_listing" componentId={id}>
                        <ListingSummary salaryTypes={salaryTypes} applicationMethods={applicationMethods} />
                      </Entity>
                    </div>
                  );
                }else{
                  return <></>
                }
              })
            }
            </div>
            <Pagination {...paginationProps}/>
          </Column>
        </Row>
      </Grid>
      {newAdd && <div className="toast-container">
          <ToastNotification
            caption=""
            iconDescription="Close"
            subtitle={<span><Link to={`/preview?id=${newAdd}`}>View it here</Link></span>}
            timeout={0}
            title="Job listing created"
            kind="success"
            lowContrast="true"
          />
        </div>}
      
    </Default>
  );
}

export const query = graphql`
query JobSearchQuery {
  allNodeJobListing(sort: {fields: field_publish_date, order: DESC}, filter: {changed: {gt: "2022-01-01"}, field_publish_date: {gt: "2022-01-01"}}) {
    nodes {
      drupal_id
      changed
      title
      field_location {
        lat
        lng
      } 
      field_expired
      field_publish_date
      field_archived  
      field_company_name
      field_preferred_qualifications {
        value
      }
      field_required_qualifications {
        value
      }
      field_description {
        value
      }
      field_benefits {
        value
      }
      field_address {
        address_line1
        administrative_area
        locality
        postal_code
      }
      field_salary_number
      field_salary_number_max
      relationships {
        field_category {
          drupal_id
          name
        }
        field_experience_level {
          drupal_id
          name
        }
        field_bilingual {
          drupal_id
          name
        }
        field_position {
          drupal_id
          name
        }
        field_salary_type {
          drupal_id
          name
          description {
            processed
          }
        }
        field_expiration{
          drupal_id
          name
        }
        field_application_method {
          drupal_id
          name
        }
      }
    }
  }
  allTaxonomyTermDatesPosted {
    nodes {
      drupal_id
      name
    }
  }
  allTaxonomyTermApplicationMethods {
    nodes {
      drupal_id
      name
    }
  }
  allTaxonomyTermCategories {
    nodes {
      drupal_id
      name
    }
  }
  allTaxonomyTermPositionTypes {
    nodes {
      drupal_id
      name
    }
  }
  allTaxonomyTermSalaryTypes {
    nodes {
      drupal_id
      name
      description {
        processed
      }
    }
  }
  allTaxonomyTermExperienceLevels {
    nodes {
      drupal_id
      name
    }
  }
  allTaxonomyTermBilingual {
    nodes {
      drupal_id
      name
    }
  }
  allTaxonomyTermDistances {
    nodes {
      drupal_id
      name
    }
  }  
  allTaxonomyTermExpirationLengths {
    nodes {
      drupal_id
      name
    }
  }  
}
`;
